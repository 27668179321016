import React, { useEffect, useState } from 'react';
import AppleIcon from '../images/AppleLogo.svg';
import { Link, Redirect } from 'react-router-dom';
import FacebookLogin from 'react-facebook-login';
import AmazonImage from '../images/amazon-icon.png';
import AppleSignin from 'react-apple-signin-auth';

type Props = {
  user: any;
  showHome: boolean;
  handleLogin(user: any): void;
  handleBack(): void;
  success(): void;
  emailVerified: boolean
};


const defaultProps: Props = {
  user: null,
  showHome: true,
  handleLogin: () => {},
  handleBack: () => {},
  success:()=>{},
  emailVerified: false
};

const LoginPagev2: React.FC<Props> = (props) => {
  const [error, setError] = useState('');
  const [appleInfo, setappleInfo] = useState();
  const [fbInfo, setfbInfo] = useState();
  const [appleSignIn, setappleSignIn] = useState(true);
  const [showFbSignIn, setFbSignIn] = useState(true);
  const loginButtonClass = 'd-flex justify-content-space-between facebook-button';
  const appId: string = process.env.REACT_APP_FACEBOOK_APP_ID || '';
  const appleRedirectURL: string = process.env.REACT_APP_APPLE_REDIRECT_URL || 'https://3c46-119-155-28-245.ngrok-free.app';
  const appleClientId: string = process.env.REACT_APP_APPLE_CLIENT_ID || 'com.yolo.meal';
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');


  const authOptions = {
    clientId: appleClientId,
    scope: 'name email',
    redirectURI: appleRedirectURL,
    state: 'state',
    nonce: 'nonce',
    usePopup: true,
  }

  useEffect(() => {
    let loginInfoStr = localStorage.getItem("loginInfo");
    if (loginInfoStr) {
      let loginInfo = JSON.parse(loginInfoStr);
      console.log(loginInfo);
      setappleInfo(loginInfo.facebookLogin);
      setappleSignIn(false);
      setFbSignIn(false);
      setfbInfo(loginInfo.appleLogin);

    }
  }, [])

  const renderAppleAuthButton = (props: JSX.IntrinsicAttributes & React.ButtonHTMLAttributes<HTMLButtonElement>) => (
    <button {...props}>
      <img
        src={AppleIcon}
        alt="Apple Icon"
        style={{ marginRight: '5px', width: '24px', height: '24px', alignItems: 'center' }}
      />
      Sign in with Apple
    </button>
  );

  const handleFacebookClick = async () => {
    //showLoader();
  };

  const getReferrer = () => {
    var path = window.location.href;
    const arr = path.split('?');
    if (arr.length === 2) {
      const params = arr[1];
      const paramsArr = params.split('=');
      if (paramsArr.length === 2 && paramsArr[0] === 'referrer') {
        return paramsArr[1];
      }
    }
    return '';
  };

  const responseFacebook = (response: { hasOwnProperty: (arg0: string) => any; accessToken: any; status: string }) => {
    // let loginInfoStr = localStorage.getItem("loginInfo");
    // if (loginInfoStr) {
    //   let loginInfo = JSON.parse(loginInfoStr);
    //   console.log(loginInfo);
    //   if (loginInfo.appleLogin === true) {
    //     return
    //   }
    // }
    if (response.hasOwnProperty('id')) {
      const data = { accessToken: response.accessToken, referrer: '' };
      if (getReferrer() !== '') {
        data.referrer = getReferrer();
      }
      fetch(process.env.REACT_APP_SERVER_URL + '/login', {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          Accept: 'application/json',
        }),
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then(
          (result) => {
            if (result.hasOwnProperty('error')) {
              if (result.hasOwnProperty('message')) {
                setError(result.message);
              } else {
                setError(result.error);
              }
            } else {
              setError('');
              props.handleLogin(result);
              let obj = {
                facebookLogin: true,
                appleLogin: false,
                simpleLogin: false
              }
              localStorage.setItem('loginInfo', JSON.stringify(obj));
              props.success();
            }
          },
          (error) => {
            setError(error.message);
            console.log(error);
          }
        );
    } else {
      var error = 'Login error';
      if (response.hasOwnProperty('status')) {
        error = response.status;
      }
      setError(error);
    }
  };

  const handleAppleResponse = (response) => {


    const authorizationInfo = response.authorization;

    let userInfo = response?.user;

    let data = {};

    if (authorizationInfo) {
      if (userInfo) {
        data = { authorization: authorizationInfo, user: userInfo, referrer: 6 };
      } else {
        data = { authorization: authorizationInfo, referrer: 6 };
      }
      fetch(process.env.REACT_APP_SERVER_URL + '/login', {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          Accept: 'application/json',
        }),

        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then(
          (result) => {
            console.log('after successful login ===> ', result);
            props.handleLogin(result);
            let obj = {
              facebookLogin: false,
              appleLogin: true,
              simpleLogin: false
            }
            localStorage.setItem('loginInfo', JSON.stringify(obj));
            props.success();
          },
          (error) => {
            setError(error.message);
            console.log(error);
          }
        );
    } else {
      var error = 'Apple Login error';
      if (response.hasOwnProperty('status')) {
        error = response.status;
      }
      setError(error);
    }
  };

  const handleJoinClick = () => {

    let data = {
      "userName":name,
      "email":email,
      "referer": getReferrer()
    };

    fetch(process.env.REACT_APP_SERVER_URL + '/login', {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
      body: JSON.stringify(data),
    })
      .then((res) =>
        res.ok ? res.json() : Promise.reject(res.json())
      ).then(
        (result) => {
          console.log('after successful login ===> ', result);
          props.handleLogin(result);
          let obj = {
            facebookLogin: false,
            appleLogin: false,
            simpleLogin: true
          }
          localStorage.setItem('loginInfo', JSON.stringify(obj));
          props.success();
        },
      (error) => {
        error.then((body) => {
          setError(body.message);
        })
      });
  };

  if (props.user.isLogged && props.showHome) {
    return <Redirect to="/home" />;
  }
  return (
    <main className={'main-bg'}>
      <div className="small-container">
          <div className="startpage-content">
            <div className="start-row d-flex full-height align-items-center">

              {!props.emailVerified && (
                <div>
                  <h1 className="pt-2 font-poppins"> Almost done!</h1>
                  <p className="pb-3 pt-2 font-poppins">We need to add your name to your favourite and your <br />email to
                  send you your prize when you win :)</p>
                </div>
                )}
              <div>{error !== '' && <p className={'danger'}>{error}</p>}</div>
              <input
                id="user-name"
                className="field md-filed text-center"
                placeholder="Add your real name here....."
                onChange={(e) => setName(e.target.value)}
              />
              <input
                id="user-email"
                className="field md-filed text-center"
                placeholder="Add email here....."
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                type="button"
                className="btn-primary"
                value="JOIN"
                onClick={handleJoinClick}
              />

              <h3> OR </h3>

              <div className="start-icons">
                <ul className="d-flex">
                  <li style={{ display: 'flex', justifyContent: 'center' }}>
                    <div className="">
                      <span className={loginButtonClass}>
                        {(fbInfo == false || showFbSignIn) && (
                          <FacebookLogin
                            appId={appId}
                            fields="email,first_name,last_name"
                            callback={responseFacebook}
                            disableMobileRedirect={true}
                          />
                        )}
                        <img style={{ display: 'none' }} src={AmazonImage} alt="Amazon" onClick={handleFacebookClick} />
                      </span>
                      <div className="apple-auth-div">
                        {(appleInfo == false || appleSignIn) && (
                          <AppleSignin
                            authOptions={authOptions}
                            uiType="dark"
                            className="apple-auth-btn"
                            buttonExtraChildren="Sign in with Apple"
                            onSuccess={(response: any) => handleAppleResponse(response)}
                            onError={(error: any) => console.error(error)}
                            skipScript={false}
                            render={renderAppleAuthButton}
                          />
                        )}

                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
      </div>
    </main>
  );
}

LoginPagev2.defaultProps = defaultProps;

export default LoginPagev2;